import React from "react"
import Container from "@material-ui/core/Container"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import SimpleContent, {
  ContentSection,
} from "../components/sections/SimpleContent"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

const Content = styled.div`
  margin-top: 4rem;
`

const NotFoundPage = () => {
  const { strapi } = useStaticQuery(graphql`
    query {
      strapi {
        notFound {
          title
          subtext
          notFoundContent {
            id
            content
            core {
              anchorTag
              id
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              titleAlign
              underlineTitle
              smallSubTitle
              divider
              animate
              marginTop
              marginBottom
              backgroundImage {
                id
                name
                alternativeText
                caption
                width
                height
                formats
                hash
                size
                url
                previewUrl
                imageFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
              }
            }
            featuredImage {
              id
              name
              alternativeText
              caption
              width
              height
              formats
              hash
              size
              url
              previewUrl
              imageFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
            align
            maxWidth
            largeFormat
            buttons {
              id
              button {
                id
                type
                link {
                  __typename
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
                title
              }
            }
            featuredVideo {
              id
              youtubeId
              youtubeMobileId
            }
            inlineMedia {
              id
              referenceName
              image {
                id
                name
                alternativeText
                caption
                width
                height
                formats
                hash
                size
                url
                previewUrl
                imageFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
              }
              mobileImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              rounded
              align
              width
              youtubeId
              youtubeMobileId
            }
          }
        }
      }
    }
  `)

  const {
    notFound: { notFoundContent },
  }: { notFound: { notFoundContent: ContentSection } } = strapi
  return (
    <Layout hasHero={false}>
      <SEO title="404: Not found" />
      <Container>
        <Content>
          <h1>{strapi && strapi.notFound.title}</h1>
          <p>{strapi && strapi.notFound.subtext}</p>
          {notFoundContent && (
            <SimpleContent {...notFoundContent} wideImage={true} />
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
